import React, { useEffect, useState } from 'react';
import {
  CheckCircle, XCircle, Clock, CircleSlash2,
} from 'lucide-react';
import { StudentDocumentsStatus, DocumentStatus } from '../../types/collections/StudentDocumentsStatus';
import FirebaseFirestoreService from '../../services/database/strategies/FirebaseFirestoreService';
import FirebaseStorageService from '../../services/storage/strategies/FirebaseStorageService';

export interface StudentDocumentsProps {
  uid: string;
}

export const StudentDocuments: React.FC<StudentDocumentsProps> = ({ uid }) => {
  const [
    studentDocumentStatus,
    setStudentDocumentStatus,
  ] = useState<StudentDocumentsStatus | undefined>();
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    FirebaseFirestoreService.getDocument<StudentDocumentsStatus>(
      'student-document-status',
      uid,
      (doc) => {
        if (doc) {
          setStudentDocumentStatus(doc);
        }
      },
      (error) => {
        console.log(error.message);
      },
    );
  }, [uid]);

  const documents = [
    { key: 'clientPrivacyPolicy', title: 'Client Privacy Policy' },
    { key: 'studentPrivacyPolicy', title: 'Student Privacy Policy' },
    { key: 'safeGuardingPolicy', title: 'Safeguarding Policy' },
    { key: 'reschedulingPolicy', title: 'Rescheduling Policy' },
    { key: 'workingTogether', title: 'Working Together' },
    { key: 'clientTerms', title: 'Client Terms' },
  ] as const;

  const getStatusIcon = (status: boolean) => (
    status ? <CheckCircle className="text-green-500 dark:text-green-400" /> : <XCircle className="text-red-500 dark:text-red-400" />
  );

  const getCounterSignIcon = (status: DocumentStatus) => (
    status.signed && !status.counterSigned
      ? <Clock className="text-yellow-500 dark:text-yellow-400" />
      : getStatusIcon(status.counterSigned)
  );

  const formatTimestamp = (timestamp: string) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const handleViewDocument = async (documentUrl: string) => {
    try {
      const link = await FirebaseStorageService.getDownloadURL(documentUrl);
      window.open(link, '_blank');
    } catch (error) {
      console.error('Error getting document URL:', error);
    }
  };

  const handleUploadDocument = async (key: keyof StudentDocumentsStatus) => {
    if (!studentDocumentStatus) return;

    setIsUploading(true);
    try {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'application/pdf';
      fileInput.click();

      const file: File = await new Promise((resolve) => {
        fileInput.onchange = (event) => {
          const selectedFile = (event.target as HTMLInputElement).files?.[0];
          if (selectedFile) resolve(selectedFile);
        };
      });

      const path = `documents/${uid}/${key}_countersigned`;
      const counterSignedDocumentUrl = await FirebaseStorageService.uploadFile(path, file);

      const updatedStatus = {
        ...studentDocumentStatus[key] as DocumentStatus,
        counterSigned: true,
        counterSignedDocumentUrl,
        counterSignedTimestamp: new Date().toISOString(),
      };

      await FirebaseFirestoreService.updateDocument(
        'student-document-status',
        uid,
        { [key]: updatedStatus },
      );

      setStudentDocumentStatus((prevStatus) => {
        if (!prevStatus) return prevStatus;
        return {
          ...prevStatus,
          [key]: updatedStatus,
        };
      });
    } catch (error) {
      console.error('Error uploading document:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const counterSignDocument = async (key: keyof StudentDocumentsStatus) => {
    if (!studentDocumentStatus) return;
    const updatedStatus = {
      ...studentDocumentStatus[key] as DocumentStatus,
      counterSigned: true,
      counterSignedDocumentUrl: '',
      counterSignedTimestamp: new Date().toISOString(),
    };

    await FirebaseFirestoreService.updateDocument(
      'student-document-status',
      uid,
      { [key]: updatedStatus },
    );

    setStudentDocumentStatus((prevStatus) => {
      if (!prevStatus) return prevStatus;
      return {
        ...prevStatus,
        [key]: updatedStatus,
      };
    });
  };

  return (
    <div className="container mx-auto px-4 py-8 dark:bg-gray-800">
      <h2 className="text-2xl font-bold mb-4 dark:text-white">Student Document Status</h2>
      {studentDocumentStatus ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600">
            <thead>
              <tr className="bg-gray-100 dark:bg-gray-800">
                <th className="py-2 px-4 border-b border-gray-300 dark:border-gray-600 text-left text-gray-700 dark:text-gray-300">Document</th>
                <th className="py-2 px-4 border-b border-gray-300 dark:border-gray-600 text-center text-gray-700 dark:text-gray-300">Read</th>
                <th className="py-2 px-4 border-b border-gray-300 dark:border-gray-600 text-center text-gray-700 dark:text-gray-300">Signed/Agreed</th>
                <th className="py-2 px-4 border-b border-gray-300 dark:border-gray-600 text-center text-gray-700 dark:text-gray-300">Counter-Signed</th>
              </tr>
            </thead>
            <tbody>
              {documents.map(({ key, title }) => (
                <tr key={key} className="hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="py-2 px-4 border-b border-gray-300 dark:border-gray-600 text-gray-800 dark:text-gray-200">{title}</td>
                  <td className="py-2 px-4 border-b border-gray-300 dark:border-gray-600 text-center">
                    <div>
                      {getStatusIcon(studentDocumentStatus[key].read)}
                      <p className="text-sm text-gray-500 dark:text-gray-400">{formatTimestamp(studentDocumentStatus[key].readTimestamp)}</p>
                    </div>
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300 dark:border-gray-600 text-center">
                    <div className="flex flex-col items-center gap-2">
                      {getStatusIcon(studentDocumentStatus[key].signed)}
                      <p className="text-sm text-gray-500 dark:text-gray-400">{formatTimestamp(studentDocumentStatus[key].signedTimestamp)}</p>
                      {key === 'clientTerms' && studentDocumentStatus[key].signed && studentDocumentStatus[key].signedDocumentUrl && (
                      <button
                        type="button"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                        onClick={() => handleViewDocument(
                          studentDocumentStatus[key].signedDocumentUrl!,
                        )}
                      >
                        View Signed Doc
                      </button>
                      )}
                    </div>
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300 dark:border-gray-600 text-center">
                    <div className="flex flex-col items-center gap-2">
                      {key === 'clientTerms' ? getCounterSignIcon(studentDocumentStatus[key]) : <CircleSlash2 className="text-amber-500 dark:text-amber-400" />}
                      <p className="text-sm text-gray-500 dark:text-gray-400">{formatTimestamp(studentDocumentStatus[key].counterSignedTimestamp)}</p>
                      {key === 'clientTerms' && studentDocumentStatus[key].counterSigned && (
                      <button
                        type="button"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                        onClick={() => {
                          if (studentDocumentStatus[key].counterSignedDocumentUrl) {
                            handleViewDocument(
                              studentDocumentStatus[key].counterSignedDocumentUrl!,
                            );
                          }
                        }}
                      >
                        View Counter Signed Doc
                      </button>
                      )}
                      {key === 'clientTerms' && studentDocumentStatus[key].signed && !studentDocumentStatus[key].counterSigned && (
                      <button
                        type="button"
                        className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800"
                        onClick={() => handleUploadDocument(key)}
                        disabled={isUploading}
                      >
                        {isUploading ? 'Uploading...' : 'Upload Counter-Signed Doc'}
                      </button>
                      )}
                      {key !== 'clientTerms' && studentDocumentStatus[key].signed && !studentDocumentStatus[key].counterSigned && (
                        <button
                          type="button"
                          className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800"
                          onClick={() => counterSignDocument(key)}
                          disabled={isUploading}
                        >
                          Accept
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="dark:text-white">Loading document status...</p>
      )}
    </div>
  );
};
