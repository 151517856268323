import React, { useEffect, useState } from 'react';
import {
  Clock, CheckCircle, XCircle, CreditCard,
} from 'lucide-react';
import StripePaymentService from '../../services/payments/strategies/StripePaymentService';
import FirebaseFirestoreService from '../../services/database/strategies/FirebaseFirestoreService';

interface Invoice {
  id: string;
  amount: number;
  status:
    | 'canceled'
    | 'processing'
    | 'requires_action'
    | 'requires_capture'
    | 'requires_confirmation'
    | 'requires_payment_method'
    | 'succeeded';
  created: number;
  dueDate: number;
}

export interface InvoicesProps {
  uid: string;
  stripeCustomerId: string | undefined;
  userDocId: string;
}

export const InvoicesPage: React.FC<InvoicesProps> = ({ uid, stripeCustomerId, userDocId }) => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isSettingUpCustomer, setIsSettingUpCustomer] = useState(false);

  useEffect(() => {
    if (stripeCustomerId) {
      const fetchInvoices = async () => {
        setIsLoading(true);
        setError(null);
        try {
          await StripePaymentService.listPaymentsForCustomer(
            stripeCustomerId!,
            (payments) => {
              setInvoices(payments.map((payment) => ({
                id: payment.id,
                amount: payment.amount,
                status: payment.status,
                created: payment.created,
                dueDate: payment.created + 30 * 24 * 60 * 60, // 30 days from creation
              })));
              setIsLoading(false);
            },
            (listError) => {
              console.error('Error fetching invoices:', listError.message);
              setError('Failed to fetch invoices. Please try again later.');
              setIsLoading(false);
            },
          );
        } catch (tryError) {
          console.error('Error in fetchInvoices:', tryError);
          setError('An unexpected error occurred. Please try again later.');
          setIsLoading(false);
        }
      };
      fetchInvoices();
    } else {
      setIsLoading(false);
    }
  }, [stripeCustomerId]);

  const setupCustomer = async () => {
    setIsSettingUpCustomer(true);
    setError(null);
    try {
      await StripePaymentService.createCustomer(
        {
          metadata: { uid }, // Store uid as metadata,
          // Add other relevant customer data here, for example:
          // name: userDoc.fullName,
          // email: userDoc.email,
          // phone: userDoc.phoneNumber,
          // address: {
          //   line1: userDoc.addressFirstLine,
          //   line2: userDoc.addressSecondLine,
          //   city: userDoc.city,
          //   postal_code: userDoc.postcode,
          //   country: 'GB', // Assuming UK, adjust as needed
          // },
        },
        async (customerId) => {
          console.log('Customer created successfully:', customerId);
          // Update the user's document in your database with the new customerId
          try {
            await FirebaseFirestoreService.updateDocument(
              'users',
              userDocId,
              { stripeCustomerId: customerId },
              () => {
                console.log('User document updated with Stripe customer ID');
                // Optionally, you might want to refresh the page or update the
                // parent component's state
                // For example, you could call a function passed as a prop:
                // onCustomerCreated(customerId);
                window.location.reload();
              },
              (updateError) => {
                console.error('Error updating user document:', updateError);
                setError('Customer created, but failed to update user record. Please contact support.');
              },
            );
          } catch (updateError) {
            console.error('Error updating user document:', updateError);
            setError('Customer created, but failed to update user record. Please contact support.');
          }
          setIsSettingUpCustomer(false);
        },
        (createError) => {
          console.error('Error creating customer:', createError.message);
          setError('Failed to set up customer. Please try again later.');
          setIsSettingUpCustomer(false);
        },
      );
    } catch (tryError) {
      console.error('Error in setupCustomer:', tryError);
      setError('An unexpected error occurred. Please try again later.');
      setIsSettingUpCustomer(false);
    }
  };

  const formatDate = (timestamp: number) => new Date(timestamp * 1000).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

  const getStatusIcon = (status: Invoice['status']) => {
    switch (status) {
      case 'succeeded':
        return <CheckCircle className="text-green-500 dark:text-green-400" />;
      case 'canceled':
        return <XCircle className="text-red-500 dark:text-red-400" />;
      default:
        return <Clock className="text-yellow-500 dark:text-yellow-400" />;
    }
  };

  const getStatusDisplay = (status: Invoice['status']) => {
    switch (status) {
      case 'succeeded':
        return 'Paid';
      case 'canceled':
        return 'Canceled';
      case 'processing':
        return 'Processing';
      case 'requires_payment_method':
        return 'Payment Required';
      case 'requires_confirmation':
        return 'Confirmation Required';
      case 'requires_action':
        return 'Action Required';
      default:
        return 'Pending';
    }
  };

  if (isLoading) {
    return <div className="text-center dark:text-white">Loading invoices...</div>;
  }

  if (!stripeCustomerId) {
    return (
      <div className="container mx-auto px-4 py-8 dark:bg-gray-800">
        <h2 className="text-2xl font-bold mb-4 dark:text-white">Invoices</h2>
        <div className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-md">
          <p className="text-gray-700 dark:text-gray-300 mb-4">You haven&apos;t set up your payment method yet. To view invoices, please set up your payment method first.</p>
          <button
            type="button"
            onClick={setupCustomer}
            disabled={isSettingUpCustomer}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <CreditCard className="mr-2" />
            {isSettingUpCustomer ? 'Setting up...' : 'Set up payment method'}
          </button>
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 dark:bg-gray-800">
      <h2 className="text-2xl font-bold mb-4 dark:text-white">Invoices</h2>
      {invoices.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600">
            <thead>
              <tr className="bg-gray-100 dark:bg-gray-800">
                <th className="py-2 px-4 border-b border-gray-300 dark:border-gray-600 text-left text-gray-700 dark:text-gray-300">Invoice ID</th>
                <th className="py-2 px-4 border-b border-gray-300 dark:border-gray-600 text-left text-gray-700 dark:text-gray-300">Amount</th>
                <th className="py-2 px-4 border-b border-gray-300 dark:border-gray-600 text-left text-gray-700 dark:text-gray-300">Status</th>
                <th className="py-2 px-4 border-b border-gray-300 dark:border-gray-600 text-left text-gray-700 dark:text-gray-300">Created Date</th>
                <th className="py-2 px-4 border-b border-gray-300 dark:border-gray-600 text-left text-gray-700 dark:text-gray-300">Due Date</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice) => (
                <tr key={invoice.id} className="hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="py-2 px-4 border-b border-gray-300 dark:border-gray-600 text-gray-800 dark:text-gray-200">{invoice.id}</td>
                  <td className="py-2 px-4 border-b border-gray-300 dark:border-gray-600 text-gray-800 dark:text-gray-200">
                    £
                    {(invoice.amount / 100).toFixed(2)}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300 dark:border-gray-600 text-gray-800 dark:text-gray-200">
                    <div className="flex items-center">
                      {getStatusIcon(invoice.status)}
                      <span className="ml-2 capitalize">{getStatusDisplay(invoice.status)}</span>
                    </div>
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300 dark:border-gray-600 text-gray-800 dark:text-gray-200">{formatDate(invoice.created)}</td>
                  <td className="py-2 px-4 border-b border-gray-300 dark:border-gray-600 text-gray-800 dark:text-gray-200">{formatDate(invoice.dueDate)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="dark:text-white">No invoices found.</p>
      )}
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
};
