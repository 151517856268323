import React, {
  createContext, useContext, ReactNode, useState, useEffect, useMemo,
} from 'react';
import { User } from '../types/User';
import FirebaseAuthService from '../services/authentication/strategies';

interface AuthState {
  isAuthenticated: boolean;
  user: null | User;
}

interface AuthContextProps {
  authState: AuthState;
  login: (
    email: string,
    password: string,
    onSuccess?: () => void,
    onFailure?: (error: string) => void
  ) => void;
  logout: (onSuccess?: () => void, onFailure?: (error: string) => void) => void;
  register: (
    email: string,
    name:string,
    password: string,
    onSuccess?: () => void,
    onFailure?: (error: string) => void
  ) => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

// Provider component
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>({ isAuthenticated: false, user: null });

  useEffect(() => {
    const unsubscribe = FirebaseAuthService.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        setAuthState({
          isAuthenticated: true,
          user: { uid: firebaseUser.uid, name: firebaseUser.name || '' },
        });
      } else {
        setAuthState({ isAuthenticated: false, user: null });
      }
    });

    return () => unsubscribe();
  }, []);

  const login = async (
    email: string,
    password: string,
    onSuccess?: () => void,
    onFailure?: (error: string) => void,
  ) => {
    try {
      await FirebaseAuthService.login(email, password);
      if (onSuccess) onSuccess();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      if (onFailure) onFailure(errorMessage);
    }
  };

  const register = async (
    email: string,
    password: string,
    name:string,
    onSuccess?: () => void,
    onFailure?: (error: string) => void,
  ) => {
    try {
      await FirebaseAuthService.register(email, password, name);
      if (onSuccess) onSuccess();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      if (onFailure) onFailure(errorMessage);
    }
  };

  const logout = async (onSuccess?: () => void, onFailure?: (error: string) => void) => {
    try {
      await FirebaseAuthService.logout();
      if (onSuccess) onSuccess();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      if (onFailure) onFailure(errorMessage);
    }
  };

  const value = useMemo(() => ({
    authState,
    login,
    logout,
    register,
  }), [authState]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
