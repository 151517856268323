import React from 'react';
import { TutorDocument } from '../../../../../../types/collections/Tutor';
import FirebaseFirestoreService from '../../../../../../services/database/strategies/FirebaseFirestoreService';

export interface CVUploadProps {
  cvUpload: boolean;
  tutorDoc: TutorDocument;
  tutorDocId: string;
}

export const CVUpload:React.FC<CVUploadProps> = ({
  cvUpload, tutorDoc, tutorDocId,
}) => (
  <div id="accordion-flush-body-2" hidden={cvUpload} aria-labelledby="accordion-flush-heading-2">
    <div className="py-5 border-b border-gray-200 dark:border-gray-700">
      <p className="mb-2 text-gray-500 dark:text-gray-400">Please upload your CV (1-2 pages long) with all of the relevant experience and educational information that makes you a good fit for Tutr.ai</p>
      <p className="font-bold text-xl text-black dark:text-white">Hiring Thesis</p>
      <p className="text-gray-500 dark:text-gray-400">
        When looking at the candidates CV we have found XXXX behaviour doesnt work well.
      </p>
      <div className="flex gap-1 py-5">
        {tutorDoc.cvUploadedLocation !== '' && (
          <button
            type="button"
            className="w-[250px] text-gray-900 bg-white border border-blue-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-blue-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
            onClick={() => {
              window.open(tutorDoc.cvUploadedLocation, '_blank');
            }}
          >
            Open CV
          </button>
        )}
        <div className="inline-flex rounded-md shadow-sm" role="group">
          <button
            onClick={() => {
              FirebaseFirestoreService.updateDocument(
                'tutors',
                tutorDocId,
                {
                  cvApproved: false,
                },
                () => {
                  console.log('CV Rejected');
                },
                () => {
                  console.log('CV Approved');
                },
              );
            }}
            type="button"
            className="px-4 py-2 text-sm font-medium text-red-900 bg-white border border-red-200 rounded-s-lg hover:bg-red-100 hover:text-red-700 focus:z-10 focus:ring-2 focus:ring-red-700 focus:text-red-700 dark:bg-red-800 dark:border-red-700 dark:text-white dark:hover:text-white dark:hover:bg-red-700 dark:focus:ring-red-500 dark:focus:text-white disabled:bg-red-50 disabled:dark:bg-red-900"
          >
            Reject CV
          </button>
          <button
            onClick={() => {
              FirebaseFirestoreService.updateDocument(
                'tutors',
                tutorDocId,
                {
                  cvApproved: true,
                },
                () => {
                  console.log('CV Rejected');
                },
                () => {
                  console.log('CV Approved');
                },
              );
            }}
            type="button"
            className="px-4 py-2 text-sm font-medium text-green-900 bg-white border border-green-200 rounded-e-lg hover:bg-green-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-green-800 dark:border-green-700 dark:text-white dark:hover:text-white dark:hover:bg-green-700 dark:focus:ring-blue-500 dark:focus:text-white disabled:bg-green-50 disabled:dark:bg-green-900"
          >
            Approve Applicant
          </button>
        </div>
      </div>
    </div>
  </div>
);
