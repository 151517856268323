export type AccountType = 'student' | 'tutor' | 'admin';

export interface UserDocument {
  accountType: AccountType,
  uid: string,
  fullName: string,
  phoneNumber: string,
  email: string,
  addressFirstLine: string,
  addressSecondLine: string,
  city: string,
  postcode: string,
  gcseYear: string,
  subjects: string[],
  stripeCustomerId?: string,
}

export const EmptyUserDocument: UserDocument = {
  accountType: 'tutor',
  uid: 'sdsd',
  fullName: '',
  phoneNumber: '',
  email: 'jondoe@tutr.ai',
  addressFirstLine: '',
  addressSecondLine: '',
  city: '',
  postcode: '',
  gcseYear: '',
  subjects: [''],
};
