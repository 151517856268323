export interface TimeRange { startTime: string; endTime: string; }

export interface TutorProfileType {
  activeStudents: number;
  description: string;
  experienceYears: number;
  biography: string;
  mondayAvailability: TimeRange;
  tuesdayAvailability: TimeRange;
  wednesdayAvailability: TimeRange;
  thursdayAvailability: TimeRange;
  fridayAvailability: TimeRange;
  saturdayAvailability: TimeRange;
  sundayAvailability: TimeRange;
  costPerLesson: number;
  cvLink: string;
  confirmed: boolean;
}

export const ExampleTutorProfileCard = {
  activeStudents: 5,
  experienceYears: 3,
  description: 'Hello! I\'m John Smith. I can help you excel at STEM using active recall!',
  biography: `## John Smith - Expert Mathematics Tutor

Hello! My name is John Smith, and I am passionate about helping students achieve their academic goals. With over 10 years of experience in teaching mathematics, I have honed my ability to explain complex concepts in a way that is both engaging and easy to understand.

### About Me

I hold a Master’s degree in Mathematics from the University of Cambridge and have taught students at various educational levels, from middle school to college. My teaching philosophy is centered on creating a supportive and interactive learning environment where students feel comfortable asking questions and exploring new ideas.

### Teaching Experience

* High School Mathematics Teacher: 5 years at Lincoln High School
* Private Tutor: 7 years of one-on-one tutoring for students aged 12-20
* Adjunct Professor: 3 years at Riverside Community College, teaching Calculus and Linear Algebra

### Subjects I Teach

* Algebra
* Geometry
* Trigonometry
* Calculus
* Statistics
* SAT/ACT Math Preparation

### My Teaching Approach

I believe that every student has a unique learning style, and I tailor my teaching methods to meet their individual needs. Whether you need help with homework, preparing for exams, or mastering difficult concepts, I am here to provide the guidance and support you need to succeed.

### Why Choose Me?

* Personalized Lessons: I customize each lesson to target your specific areas of difficulty.
* Flexible Scheduling: I offer flexible scheduling options to fit your busy life.
* Proven Success: My students consistently achieve higher grades and improved test scores.
* Patient and Encouraging: I foster a positive learning atmosphere where you can build confidence in your abilities.

### Testimonials

“John has been an incredible tutor for my daughter. Her grades have improved significantly, and she actually looks forward to her math lessons now!” - Sarah T.

“Thanks to John’s guidance, I scored in the 90th percentile on my SAT Math section. He made even the hardest topics seem manageable.” - James R.
`,
  mondayAvailability: { startTime: '08:00', endTime: '20:00' },
  tuesdayAvailability: { startTime: '08:00', endTime: '20:00' },
  wednesdayAvailability: { startTime: '08:00', endTime: '20:00' },
  thursdayAvailability: { startTime: '08:00', endTime: '20:00' },
  fridayAvailability: { startTime: '08:00', endTime: '20:00' },
  saturdayAvailability: { startTime: '08:00', endTime: '20:00' },
  sundayAvailability: { startTime: '08:00', endTime: '20:00' },
  costPerLesson: 55.3,
  cvLink: 'NA',
  confirmed: false,
};
