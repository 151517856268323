import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';

export interface AuthenticationLoginProps {
  switchMode: () => void;
}

const AuthenticationLogin: React.FC<AuthenticationLoginProps> = ({ switchMode }) => {
  const [form, setForm] = useState(
    { email: '', password: '' },
  );
  const [error, setError] = useState('');
  const { login } = useAuth();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    login(
      form.email,
      form.password,
      () => {
        window.location.href = '/';
      },
      (loginErr) => {
        if (loginErr) setError(loginErr);
      },
    );
  };

  return (
    <form onSubmit={handleSubmit} className="w-full">
      {
        error !== '' && (
        <div
          className="p-4 mb-4 text-sm text-red-800 border border-red-500 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
          role="alert"
        >
          <span className="font-medium">Authentication Failed! </span>
          {error}
        </div>
        )
      }

      <div className="mb-6">
        <label
          htmlFor="email"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Email address
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            type="email"
            id="email"
            name="email"
            required
            placeholder="mail@gmail.com"
            value={form.email}
            onChange={handleInputChange}
          />
        </label>
      </div>
      <div className="mb-6">
        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Password
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            type="password"
            id="password"
            placeholder="•••••••••"
            name="password"
            value={form.password}
            onChange={handleInputChange}
            required
          />
        </label>
      </div>
      <div className="flex items-start mb-6">
        <label htmlFor="remember" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          <div className="flex items-center h-5">
            <input id="remember" type="checkbox" value="" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" required />
          </div>
          I agree with the
          <a href="/" className="text-blue-600 hover:underline dark:text-blue-500"> terms and conditions</a>
          .
        </label>
      </div>
      <div className="flex gap-2 ">
        <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
        <button
          type="button"
          className="text-white bg-yellow-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
          onClick={() => { switchMode(); }}
        >
          Switch to Register
        </button>
      </div>
    </form>
  );
};

export default AuthenticationLogin;
