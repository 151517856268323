import React from 'react';
import { UserDocument } from '../../types/collections/User';
import { TutorOnboarding } from './landing/onboarding-landing/tutor-onboarding/TutorOnboarding';

export interface TutorPageProps {
  userDoc: UserDocument;
}

export const TutorPage: React.FC<TutorPageProps> = ({ userDoc }) => (
  <div>
    <h1 className="text-2xl font-bold">Tutor Portal</h1>
    <br />
    <TutorOnboarding userDoc={userDoc} />
  </div>
);
