import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  FirestoreError,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  getFirestore,
  onSnapshot,
  Unsubscribe,
  startAfter,
  limit,
  updateDoc,
  WithFieldValue,
} from 'firebase/firestore';
import app from '../../../config/firebaseConfig';
import {
  DatabaseService, FailureCallback, SuccessCallback, UpdateCallback,
} from '../DatabaseInterface';

const db = getFirestore(app);

type QueryResult<T> = T & { id: string };

const FirebaseDatabaseService: DatabaseService = {

  async addDocument(
    collectionPath: string,
    data: object,
    onSuccess?: SuccessCallback<string>,
    onFailure?: FailureCallback,
  ): Promise<void> {
    try {
      const safeData: WithFieldValue<DocumentData> = data;
      const docRef = await addDoc(collection(db, collectionPath), safeData);
      if (onSuccess) onSuccess(docRef.id);
    } catch (error) {
      if (onFailure) onFailure(error as Error);
    }
  },

  async getDocument<T>(
    collectionPath: string,
    docId: string,
    onSuccess?: SuccessCallback<T | null>,
    onFailure?: FailureCallback,
  ): Promise<void> {
    try {
      const docRef = doc(db, collectionPath, docId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        if (onSuccess) onSuccess(docSnap.data() as T);
      } else if (onSuccess) onSuccess(null);
    } catch (error) {
      if (onFailure) onFailure(error as Error);
    }
  },

  async updateDocument<T>(
    collectionPath: string,
    docId: string,
    data: Partial<T>,
    onSuccess?: SuccessCallback<void>,
    onFailure?: FailureCallback,
  ): Promise<void> {
    try {
      const docRef = doc(db, collectionPath, docId);
      await updateDoc(docRef, data);
      if (onSuccess) onSuccess();
    } catch (error) {
      if (onFailure) onFailure(error as Error);
    }
  },

  async deleteDocument(
    collectionPath: string,
    docId: string,
    onSuccess?: SuccessCallback<void>,
    onFailure?: FailureCallback,
  ): Promise<void> {
    try {
      const docRef = doc(db, collectionPath, docId);
      await deleteDoc(docRef);
      if (onSuccess) onSuccess();
    } catch (error) {
      if (onFailure) onFailure(error as Error);
    }
  },

  listenToDocument <T>(
    collectionPath: string,
    docId: string,
    onUpdate: UpdateCallback<T>,
    onError: (error: FirestoreError) => void,
  ): Unsubscribe {
    const documentRef: DocumentReference = doc(db, collectionPath, docId);
    return onSnapshot(
      documentRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          onUpdate(docSnapshot.data() as T);
        }
      },
      onError,
    );
  },

  getNewDocumentID(
    collectionName: string,
    onSuccess: SuccessCallback<string>,
  ): Promise<string> {
    const newDocRef = doc(collection(db, collectionName));
    onSuccess(newDocRef.id);
    return Promise.resolve(newDocRef.id);
  },

  async queryDocuments<T>(
    collectionPath: string,
    queryField: string,
    queryValue: string,
    orderByField: string,
    pageSize: number, // Specify the number of documents per page
    startAfterDocId?: string, // Optional parameter for pagination
    onSuccess?: SuccessCallback<T[]>,
    onFailure?: FailureCallback,
  ): Promise<void> {
    try {
      let q = query(collection(db, collectionPath), where(queryField, '==', queryValue), orderBy(orderByField));

      // If startAfterDocId is provided, set the starting point for pagination
      if (startAfterDocId) {
        const startAfterDoc = doc(db, collectionPath, startAfterDocId);
        q = query(q, startAfter(startAfterDoc));
      }

      // Limit the number of documents returned per page
      q = query(q, limit(pageSize));

      const querySnapshot = await getDocs(q);
      const documents: QueryResult<T>[] = [];
      querySnapshot.forEach((docSnapshot) => {
        const data = docSnapshot.data() as T;
        const result: QueryResult<T> = { ...data, id: docSnapshot.id };
        documents.push(result);
      });
      if (onSuccess) onSuccess(documents);
    } catch (error) {
      if (onFailure) onFailure(error as FirestoreError);
    }
  },
  async getRandomDocument<T>(
    collectionPath: string,
    onSuccess?: SuccessCallback<T | null>,
    onFailure?: FailureCallback,
  ): Promise<void> {
    try {
      const q = query(collection(db, collectionPath));
      const querySnapshot = await getDocs(q);
      const documents: QueryResult<T>[] = [];
      querySnapshot.forEach((docSnapshot) => {
        const data = docSnapshot.data() as T;
        const result: QueryResult<T> = { ...data, id: docSnapshot.id };
        documents.push(result);
      });

      if (documents.length > 0) {
        const randomIndex = Math.floor(Math.random() * documents.length);
        const randomDocument = documents[randomIndex];
        if (onSuccess) onSuccess(randomDocument as T);
      } else if (onSuccess) onSuccess(null); // No documents found
    } catch (error) {
      if (onFailure) onFailure(error as FirestoreError);
    }
  },

};

export default FirebaseDatabaseService;
