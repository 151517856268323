import React, { useEffect, useState } from 'react';
import Identicon from 'react-hooks-identicons';
import ScrollableContentLayout from '../layouts/ScrollableContentLayout';
import FirebaseFirestoreService from '../services/database/strategies/FirebaseFirestoreService';
import { EmptyUserDocument, UserDocument } from '../types/collections/User';
import { Breadcrumbs } from '../components/breadcrumbs/Breadcrumbs';
import { ProfilePage } from '../components/page-components/ProfilePage';
import { TutorPage } from '../components/page-components/TutorPage';
import { TutorProfileSection } from '../components/tutor-profile/TutorProfileSection';
import { StudentDocuments } from '../components/student-documents/StudentDocuments';
import { InvoicesPage } from '../components/page-components/InvoicesPage';

export interface UsersProps {

}

type UsersPages = 'Profile' | 'Tutor Onboarding' | 'Tutor Profile' | 'Invoices' | 'Student Onboarding';

export const Users: React.FC<UsersProps> = () => {
  const [uid, setUid] = useState('');
  const [userDoc, setUserDoc] = useState<UserDocument>(EmptyUserDocument);
  const [userDocId, setUserDocId] = useState('');
  const [pageOpened, setPageOpened] = useState<UsersPages>('Profile');

  useEffect(() => {
    // Get the current URLSearchParams object
    const queryParams = new URLSearchParams(window.location.search);
    const uidParam = queryParams.get('uid');
    if (uidParam) setUid(uidParam);
  }, []);

  useEffect(() => {
    FirebaseFirestoreService.queryDocuments(
      'users',
      'uid',
      uid,
      'fullName',
      1,
      undefined,
      (docs) => {
        if (docs.length > 0) {
          setUserDoc(docs[0] as UserDocument);
          if (docs[0].id) setUserDocId(docs[0].id);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }, [uid]);

  return (
    <ScrollableContentLayout>
      {userDoc === EmptyUserDocument && (
      <div className="absolute z-50 w-full h-full bg-white dark:bg-gray-900 flex items-center justify-center mx-auto">
        <p className="text-black dark:text-white text-2xl animate-bounce">Loading...</p>
      </div>
      )}

      <section className="bg-gray-50 dark:bg-gray-800 border-t border-gray-100">
        <div className="gap-5 items-start py-8 px-4 mx-auto max-w-screen-xl flex flex-col w-full">
          <Breadcrumbs hrefs={['/', '/']} pathNames={['Home', userDoc.accountType === 'student' ? 'Students' : 'Tutors', userDoc.fullName]} />
          <div className="flex gap-4 sm:gap-16 flex-col sm:flex-row">
            <Identicon string={userDoc.email} size={100} />
            <div>
              <h2 className="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">{userDoc.fullName}</h2>
              <div className="inline-flex rounded-md shadow-sm" role="group">
                <button type="button" onClick={() => { setPageOpened('Profile'); }} disabled={pageOpened === 'Profile'} className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white disabled:bg-blue-500 disabled:dark:bg-blue-500">
                  <svg className="w-3 h-3 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                  </svg>
                  Profile
                </button>
                {userDoc.accountType === 'tutor' && (
                <button
                  type="button"
                  onClick={() => {
                    setPageOpened('Tutor Onboarding');
                  }}
                  disabled={pageOpened === 'Tutor Onboarding'}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white disabled:bg-blue-500 disabled:dark:bg-blue-500"
                >
                  <svg
                    className="w-3 h-3 me-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 12.25V1m0 11.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M4 19v-2.25m6-13.5V1m0 2.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M10 19V7.75m6 4.5V1m0 11.25a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM16 19v-2"
                    />
                  </svg>
                  Tutor Onboarding
                </button>
                )}
                {userDoc.accountType === 'tutor' && (
                <button
                  type="button"
                  onClick={() => {
                    setPageOpened('Tutor Profile');
                  }}
                  disabled={pageOpened === 'Tutor Profile'}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-l border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white disabled:bg-blue-500 disabled:dark:bg-blue-500"
                >
                  <svg
                    className="w-3 h-3 me-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 12.25V1m0 11.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M4 19v-2.25m6-13.5V1m0 2.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M10 19V7.75m6 4.5V1m0 11.25a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM16 19v-2"
                    />
                  </svg>
                  Tutor Profile
                </button>
                )}
                {userDoc.accountType === 'student' && (
                  <button
                    type="button"
                    onClick={() => {
                      setPageOpened('Student Onboarding');
                    }}
                    disabled={pageOpened === 'Student Onboarding'}
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-l border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white disabled:bg-blue-500 disabled:dark:bg-blue-500"
                  >
                    <svg
                      className="w-3 h-3 me-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 12.25V1m0 11.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M4 19v-2.25m6-13.5V1m0 2.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M10 19V7.75m6 4.5V1m0 11.25a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM16 19v-2"
                      />
                    </svg>
                    Student Onboarding
                  </button>
                )}
                <button
                  type="button"
                  onClick={() => { setPageOpened('Invoices'); }}
                  disabled={pageOpened === 'Invoices'}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white disabled:bg-blue-500 disabled:dark:bg-blue-500"
                >
                  <svg className="w-3 h-3 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
                    <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                  </svg>
                  Invoices
                </button>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section className="text:black dark:text-white h-full">
        <div className="py-8 px-4 mx-auto max-w-screen-xl w-full">
          {pageOpened === 'Profile' && <ProfilePage userDoc={userDoc} />}
          {userDoc.accountType === 'tutor' && pageOpened === 'Tutor Onboarding' && <TutorPage userDoc={userDoc} /> }
          {userDoc.accountType === 'tutor' && pageOpened === 'Tutor Profile' && <TutorProfileSection uid={uid} userDoc={userDoc} /> }
          {userDoc.accountType === 'student' && pageOpened === 'Student Onboarding' && <StudentDocuments uid={uid} /> }
          {pageOpened === 'Invoices' && <InvoicesPage uid={uid} stripeCustomerId={userDoc.stripeCustomerId} userDocId={userDocId} /> }
        </div>
      </section>

    </ScrollableContentLayout>
  );
};
