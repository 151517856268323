import React, { useEffect, useState } from 'react';
import { CVUpload } from './steps/CVUpload';
import { IDConfirmation } from './steps/IDConfirmation';
import ReferencesUpload from './steps/ReferencesUpload';
import { TutorDocument } from '../../../../../types/collections/Tutor';

interface StepsCompletedIconProps {
  isComplete: boolean,
}
const StepCompletedIcon:React.FC<StepsCompletedIconProps> = ({ isComplete }) => {
  if (!isComplete) {
    return (
      <span
        className="inline-flex items-center justify-center w-6 h-6 me-2 text-sm font-semibold text-blue-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-400"
      >
        <svg
          className="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
          />
        </svg>
        <span className="sr-only">Pending</span>
      </span>
    );
  }
  return (
    <span className="inline-flex items-center justify-center w-6 h-6 me-2 text-sm font-semibold text-blue-800 bg-blue-100 rounded-full dark:bg-gray-700 dark:text-blue-400">
      <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path fill="currentColor" d="m18.774 8.245-.892-.893a1.5 1.5 0 0 1-.437-1.052V5.036a2.484 2.484 0 0 0-2.48-2.48H13.7a1.5 1.5 0 0 1-1.052-.438l-.893-.892a2.484 2.484 0 0 0-3.51 0l-.893.892a1.5 1.5 0 0 1-1.052.437H5.036a2.484 2.484 0 0 0-2.48 2.481V6.3a1.5 1.5 0 0 1-.438 1.052l-.892.893a2.484 2.484 0 0 0 0 3.51l.892.893a1.5 1.5 0 0 1 .437 1.052v1.264a2.484 2.484 0 0 0 2.481 2.481H6.3a1.5 1.5 0 0 1 1.052.437l.893.892a2.484 2.484 0 0 0 3.51 0l.893-.892a1.5 1.5 0 0 1 1.052-.437h1.264a2.484 2.484 0 0 0 2.481-2.48V13.7a1.5 1.5 0 0 1 .437-1.052l.892-.893a2.484 2.484 0 0 0 0-3.51Z" />
        <path fill="#fff" d="M8 13a1 1 0 0 1-.707-.293l-2-2a1 1 0 1 1 1.414-1.414l1.42 1.42 5.318-3.545a1 1 0 0 1 1.11 1.664l-6 4A1 1 0 0 1 8 13Z" />
      </svg>
      <span className="sr-only">Completed</span>
    </span>
  );
};

export interface TutorInformationProps {
  tutorDocument: TutorDocument;
  tutorDocId: string;
}

export const TutorInformation: React.FC<TutorInformationProps> = ({
  tutorDocument,
  tutorDocId,
}) => {
  const [stepsHidden, setStepsHidden] = useState({
    cvUpload: true,
    idConfirmation: true,
    references: true,
    uniform: true,
    agreeToEmploymentStatus: true,
  });

  const [stepsCompleted, setStepsCompleted] = useState({
    cvUpload: false,
    idConfirmation: false,
    references: false,
    uniform: false,
    agreeToEmploymentStatus: false,
  });

  useEffect(() => {
    setStepsCompleted((prevState) => ({
      ...prevState,
      cvUpload: !!tutorDocument.cvApproved,
      idConfirmation: !!tutorDocument.identificationApproved,
      references: tutorDocument.reference1Email !== '' && tutorDocument.reference2Email !== '',
      uniform: tutorDocument.uniformAgreementDate !== undefined,
      agreeToEmploymentStatus: tutorDocument.employmentStatusAgreementDate !== undefined,
    }));
  }, [tutorDocument]);

  return (
    <div>
      <h2 id="accordion-flush-heading-2">
        <button
          type="button"
          className="flex items-center justify-between w-full py-5 font-medium text-left rtl:text-right text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"
          data-accordion-target="#accordion-flush-body-2"
          aria-expanded="false"
          aria-controls="accordion-flush-body-2"
          onClick={() => {
            setStepsHidden((prevStepsHidden) => ({
              ...prevStepsHidden,
              cvUpload: !prevStepsHidden.cvUpload,
            }));
          }}
        >
          <span>
            <StepCompletedIcon isComplete={stepsCompleted.cvUpload} />
            Review the CV
          </span>
          {stepsHidden.idConfirmation && (
          <svg className="w-3 h-3 rotate-180 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" d="M9 5 5 1 1 5" />
          </svg>
          )}
        </button>
      </h2>
      <CVUpload
        cvUpload={stepsHidden.cvUpload}
        tutorDoc={tutorDocument}
        tutorDocId={tutorDocId}
      />

      {/* Identification Confirmation */}
      <h2 id="accordion-flush-heading-1">
        <button
          type="button"
          className="flex items-center justify-between w-full py-5 font-medium text-left rtl:text-right text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"
          data-accordion-target="#accordion-flush-body-1"
          aria-expanded="true"
          aria-controls="accordion-flush-body-1"
          onClick={() => {
            setStepsHidden((prevHidden) => ({
              ...prevHidden,
              idConfirmation: !prevHidden.idConfirmation,
            }));
          }}
        >
          <span>
            <StepCompletedIcon isComplete={stepsCompleted.idConfirmation} />
            Review Passport
          </span>
          {stepsHidden.idConfirmation && (
          <svg className="w-3 h-3 rotate-180 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" d="M9 5 5 1 1 5" />
          </svg>
          )}
        </button>
      </h2>
      <IDConfirmation
        idConfirmed={stepsHidden.idConfirmation}
        tutorDoc={tutorDocument}
        tutorDocId={tutorDocId}
      />

      {/* Tutor References */}
      <h2 id="accordion-flush-heading-3">
        <button
          type="button"
          className="flex items-center justify-between w-full py-5 font-medium text-left rtl:text-right text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"
          data-accordion-target="#accordion-flush-body-3"
          aria-expanded="false"
          aria-controls="accordion-flush-body-3"
          onClick={() => {
            setStepsHidden((prevStepsHidden) => ({
              ...prevStepsHidden,
              references: !prevStepsHidden.references,
            }));
          }}
        >
          <span>
            <StepCompletedIcon isComplete={stepsCompleted.references} />
            Email References
          </span>
          {stepsHidden.idConfirmation && (
          <svg className="w-3 h-3 rotate-180 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" d="M9 5 5 1 1 5" />
          </svg>
          )}
        </button>
      </h2>
      <ReferencesUpload
        referencesUploaded={stepsHidden.references}
        tutorDoc={tutorDocument}
        tutorDocumentId={tutorDocId}
      />

      {/* Uniform Status */}

      <div className="pt-5 w-full flex gap-2">
        {
          !stepsCompleted.uniform
            ? <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">Awaiting Uniform Agreement</span>
            : <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">Uniform Agreed</span>
        }
        {
          !stepsCompleted.agreeToEmploymentStatus
            ? <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">Awaiting Employment Agreement</span>
            : <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">Employment Agreed</span>
        }
      </div>
    </div>
  );
};
