import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './styles/tailwind.css';
import Landing from './pages/Landing';
import About from './pages/About';
import { AuthProvider } from './contexts/AuthContext';
import Authentication from './pages/Authentication';
import { Users } from './pages/Users';

const App: React.FC = () => (
  <AuthProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />

        {/* Additional Authentication Routes */}
        <Route path="/authenticate" element={<Authentication />} />

        <Route path="/users" element={<Users />} />

      </Routes>
    </Router>
  </AuthProvider>
);

export default App;
