import React, { useEffect, useState } from 'react';
import EngagedLayout from '../../layouts/EngagedLayout';
import { useAuth } from '../../contexts/AuthContext';

const OnboardingLanding = () => {
  const { authState, logout } = useAuth();
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  return (
    <EngagedLayout>
      <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800 absolute top-0 w-full">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <a href="/" className="flex items-center">
            {/* <img src="Logo Source Here?" className="mr-3 h-6 sm:h-9" alt="logo" /> */}
            <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Tütr.admin</span>
          </a>
          <div className="flex items-center lg:order-3">

            {authState.isAuthenticated && (
              <button
                type="button"
                className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
                onClick={() => { logout(() => { window.location.href = '/'; }, () => { window.location.href = '/'; }); }}
              >
                Signout
              </button>
            )}

            <button
              data-tooltip-target="toggle-colors-example-toggle-dark-mode-tooltip"
              type="button"
              data-toggle-dark="dark"
              className="flex items-center w-9 h-9 justify-center text-xs font-medium text-gray-700 bg-white border border-gray-200 rounded-lg toggle-dark-state-example hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 dark:bg-gray-800 focus:outline-none dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              onClick={() => { setDarkMode(!darkMode); }}
            >
              <svg
                data-toggle-icon="moon"
                className={`w-3.5 h-3.5 ${!darkMode ? '' : 'hidden'}`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 20"
              >
                <path d="M17.8 13.75a1 1 0 0 0-.859-.5A7.488 7.488 0 0 1 10.52 2a1 1 0 0 0 0-.969A1.035 1.035 0 0 0 9.687.5h-.113a9.5 9.5 0 1 0 8.222 14.247 1 1 0 0 0 .004-.997Z" />
              </svg>
              <svg data-toggle-icon="sun" className={`w-3.5 h-3.5 ${darkMode ? '' : 'hidden'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 15a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm0-11a1 1 0 0 0 1-1V1a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1Zm0 12a1 1 0 0 0-1 1v2a1 1 0 1 0 2 0v-2a1 1 0 0 0-1-1ZM4.343 5.757a1 1 0 0 0 1.414-1.414L4.343 2.929a1 1 0 0 0-1.414 1.414l1.414 1.414Zm11.314 8.486a1 1 0 0 0-1.414 1.414l1.414 1.414a1 1 0 0 0 1.414-1.414l-1.414-1.414ZM4 10a1 1 0 0 0-1-1H1a1 1 0 0 0 0 2h2a1 1 0 0 0 1-1Zm15-1h-2a1 1 0 1 0 0 2h2a1 1 0 0 0 0-2ZM4.343 14.243l-1.414 1.414a1 1 0 1 0 1.414 1.414l1.414-1.414a1 1 0 0 0-1.414-1.414ZM14.95 6.05a1 1 0 0 0 .707-.293l1.414-1.414a1 1 0 1 0-1.414-1.414l-1.414 1.414a1 1 0 0 0 .707 1.707Z" />
              </svg>
              <span className="sr-only">Toggle dark/light mode</span>
            </button>
          </div>
        </div>
      </nav>

      <section className=" py-[100px] bg-white dark:bg-gray-900 w-full min-h-[80vh] flex flex-col justify-center items-center gap-2 dark:text-white tex-black">
        <h1 className="text-4xl">Complete Onboarding First</h1>
        <span>
          You need to finish your user onboarding on the main
          tutr.ai website before continuing here.
        </span>
      </section>
    </EngagedLayout>
  );
};

export default OnboardingLanding;
