import React from 'react';
import { UserDocument } from '../../types/collections/User';

export interface ProfilePageProps {
  userDoc: UserDocument;
}

type Subject = {
  id: string;
  label: string;
  colors: {
    from: string;
    to: string;
  };
};
const subjects: Subject[] = [
  { id: 'step', label: 'STEP', colors: { from: '#8e24aa', to: '#2196f3' } },
  { id: 'science', label: 'English', colors: { from: '#00bcd4', to: '#2196f3' } },
  { id: 'mathematics', label: 'Mathematics', colors: { from: '#66bb6a', to: '#1e88e5' } },
  { id: 'computer-science', label: 'Computer Science', colors: { from: '#4db6ac', to: '#dce775' } },
  { id: 'machine-learning', label: 'Machine Learning', colors: { from: '#ef9a9a', to: '#e57373' } },
  { id: 'physics', label: 'Physics', colors: { from: '#9c27b0', to: '#e91e63' } },
  { id: 'chemistry', label: 'Chemistry', colors: { from: '#e91e63', to: '#ff7043' } },
];

export const ProfilePage:React.FC<ProfilePageProps> = ({ userDoc }) => (
  <div className="py-8">
    <h1 className="text-2xl font-bold mb-4">User Information</h1>
    <p>View all of the user information at a glance and perform specific actions.</p>
    <div className="w-full my-5 h-[1px] bg-gray-500 rounded-full" />
    <h1 className="text-2xl font-bold mb-4">Account Information</h1>

    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              User Field
            </th>
            <th scope="col" className="px-6 py-3">
              <div className="flex items-center">
                Value
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th scope="row" className="px-6 py-4">
              Full Name
            </th>
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              {userDoc.fullName}
            </td>
          </tr>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th scope="row" className="px-6 py-4">
              Unique User Identifier
            </th>
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              {userDoc.uid}
            </td>
          </tr>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th scope="row" className="px-6 py-4">
              Account Type
            </th>
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              {userDoc.accountType}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />
    <h1 className="text-2xl font-bold mb-4">Contact Information</h1>
    <div className="flex gap-2">
      <button type="button" className="px-3 py-2 text-sm font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z" />
        </svg>
        {userDoc.phoneNumber}
      </button>
      <button type="button" className="px-3 py-2 text-sm font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        <svg className="w-3 h-3 text-white me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
          <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
          <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
        </svg>
        {userDoc.email}
      </button>
    </div>
    <br />
    <h1 className="text-2xl font-bold mb-4">Address Information</h1>
    <p>{userDoc.addressFirstLine}</p>
    <p>{userDoc.addressSecondLine}</p>
    <p>{userDoc.postcode}</p>
    <p>{userDoc.city}</p>
    <br />
    <h1 className="text-2xl font-bold mb-4">Academic Information</h1>
    <div className="flex gap-2 m-2">
      <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 4h12M6 4v16M6 4H5m13 0v16m0-16h1m-1 16H6m12 0h1M6 20H5M9 7h1v1H9V7Zm5 0h1v1h-1V7Zm-5 4h1v1H9v-1Zm5 0h1v1h-1v-1Zm-3 4h2a1 1 0 0 1 1 1v4h-4v-4a1 1 0 0 1 1-1Z" />
      </svg>
      <p>{userDoc.gcseYear}</p>
    </div>
    <div className="flex gap-2 flex-wrap m-2">
      {userDoc.subjects.map((subjectId) => {
        const subject = subjects.filter((elem) => elem.id === subjectId)[0];
        if (subject !== undefined) {
          return (
            <button
              type="button"
              key={subject.id}
              style={{
                backgroundImage: `linear-gradient(to bottom right, ${subject.colors.from}, ${subject.colors.to})`,
              }}
              className="relative inline-flex items-center justify-center p-0.5 mb-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group hover:text-white dark:text-white focus:ring-4 focus:outline-none"
            >
              <span
                className="relative px-5 py-2.5 transition-all ease-in duration-75 rounded-md bg-white dark:bg-gray-900 group-hover:bg-opacity-0"
              >
                {subject.label}
              </span>
            </button>
          );
        }
        return <p>Not Found</p>;
      })}
    </div>
    <br />
    <h1 className="text-2xl font-bold mb-4">Admin Operations</h1>
    <div>
      <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Default</button>
      <button type="button" className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Make Admin</button>
      <button type="button" className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Delete Account</button>
    </div>
  </div>
);
