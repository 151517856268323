import React from 'react';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/Footer';

interface ContentLayoutProps {
    children: React.ReactNode;
}

const ScrollableContentLayout: React.FC<ContentLayoutProps> = ({ children }) => (
  <div className="flex flex-col min-h-screen dark:bg-gray-900">
    <Navbar />
    <main className="flex-grow relative">
      {children}
    </main>
    <Footer />
  </div>
);

export default ScrollableContentLayout;
