import React, { useState } from 'react';
import Jdenticon from 'react-jdenticon';
import Markdown from 'react-markdown';
import { UserDocument } from '../../types/collections/User';
import { TutorProfileType } from '../../types/TutorProfile';

export interface TimeRangeChartProps {
  day: string;
  startTime: string;
  endTime: string;
}

const TimeRangeChart: React.FC<TimeRangeChartProps> = ({ day, startTime, endTime }) => {
  const [hover, setHover] = useState(false);

  const timeToMinutes = (time: string) => {
    const hours = parseInt(time.substring(0, 2), 10);
    const minutes = parseInt(time.substring(3), 10);
    return hours * 60 + minutes;
  };

  // Total minutes in a day
  const totalMinutesInDay = 24 * 60;

  // Calculate the position and width
  const startMinutes = timeToMinutes(startTime);
  const endMinutes = timeToMinutes(endTime);
  const durationMinutes = endMinutes - startMinutes;

  const startPercentage = (startMinutes / totalMinutesInDay) * 100;
  const durationPercentage = (durationMinutes / totalMinutesInDay) * 100;

  return (
    <div className="w-[25px] h-full relative">
      <div
        className="w-[25px] h-[90%] rounded-md bg-gray-200 border border-gray-400 relative"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div
          className="absolute bg-primary rounded-md"
          style={{
            top: `${startPercentage}%`,
            height: `${durationPercentage}%`,
            width: '100%',
          }}
        />
      </div>
      {hover && (
        <div
          className="absolute bg-white text-black p-4 rounded-md shadow-lg z-20 border border-gray-500 flex flex-col"
          style={{
            top: `${startPercentage}%`,
            transform: 'translateY(-100%)',
            left: '110%', // Adjust this value to position the tooltip properly
          }}
        >
          <p className="text-gray-500 text-sm">
            Start:
            {` ${startTime}`}
          </p>
          <p className="text-gray-500 text-sm">
            End:
            {` ${endTime}`}
          </p>
        </div>
      )}
      <p className="text-[10px] font-light text-center">{day}</p>
    </div>
  );
};

interface TutorProfileCardProps {
  userDoc: UserDocument;
  tutorProfileType: TutorProfileType;
}

export const TutorProfileCard: React.FC<TutorProfileCardProps> = (
  { userDoc, tutorProfileType },
) => {
  const [openedCard, setOpenedCard] = useState(false);
  return (
    <div
      className="bg-white flex border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 m-auto flex-wrap transition-all"
    >
      <div className="flex flex-col items-center p-10 max-w-sm">
        <div className="flex gap-2 w-full py-2">
          <div className="overflow-hidden rounded-full w-[100px] h-[100px]">
            <Jdenticon
              size="100"
              value={userDoc.fullName}
            />
          </div>
          <div className="h-full flex flex-col justify-center">
            <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">{userDoc.fullName}</h5>
            <span>
              <span className="font-bold">
                £
                {tutorProfileType.costPerLesson}
              </span>
              {' '}
              per lesson
            </span>
            <span className="flex gap-2 items-center">
              <svg className="w-4 h-4 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path d="M18.045 3.007 12.31 3a1.965 1.965 0 0 0-1.4.585l-7.33 7.394a2 2 0 0 0 0 2.805l6.573 6.631a1.957 1.957 0 0 0 1.4.585 1.965 1.965 0 0 0 1.4-.585l7.409-7.477A2 2 0 0 0 21 11.479v-5.5a2.972 2.972 0 0 0-2.955-2.972Zm-2.452 6.438a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
              </svg>
              <p className="font-light">
                {tutorProfileType.activeStudents}
                {' '}
                active students
              </p>
            </span>
            <span className="flex gap-2 items-center">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2ZM7 12.5a.5.5 0 0 1 .495.43.762.762 0 0 0 .157.096c.213.106.553.208 1.005.295.896.173 2.111.262 3.343.262 1.232 0 2.447-.09 3.343-.262.452-.087.792-.19 1.006-.295a.764.764 0 0 0 .156-.096.5.5 0 0 1 .995.07c0 1.19-.644 2.438-1.618 3.375C14.9 17.319 13.531 18 12 18c-1.531 0-2.9-.681-3.882-1.625C7.144 15.438 6.5 14.19 6.5 13a.5.5 0 0 1 .5-.5Zm9.519.417.003-.004-.003.004Zm-9.038 0a.017.017 0 0 1-.003-.004l.003.004Zm.901-4.853L9 6.81l.619 1.253 1.381.2-1 .976.236 1.376-1.237-.65-1.235.65L8 9.239l-1-.975 1.382-.2Zm6 0L15 6.81l.619 1.253 1.381.2-1 .976.236 1.376-1.237-.65-1.235.65L14 9.239l-1-.975 1.382-.2Z"
                  clipRule="evenodd"
                />
              </svg>
              <p className="font-light">
                {tutorProfileType.experienceYears}
                {' '}
                years of experience
              </p>
            </span>
          </div>
        </div>
        <span className="text-sm text-gray-500 dark:text-gray-400 text-center">
          <span className="font-bold">Subjects: </span>
          {userDoc.subjects.join(', ')}
        </span>
        <Markdown className="my-2 text-center prose dark:prose-dark">
          {tutorProfileType.description}
        </Markdown>
        <div className="flex mt-2">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Pick
            Tutor
          </button>
          <a
            href="/"
            className="py-2 px-4 ms-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          >
            Hide
            Profile
          </a>
        </div>
      </div>
      <div className="px-4 py-4">
        <button
          id="dropdownButton"
          data-dropdown-toggle="dropdown"
          className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
          type="button"
          onClick={() => {
            setOpenedCard((prevState) => !prevState);
          }}
        >
          <span className="sr-only">Open dropdown</span>
          {openedCard ? (
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14 8-4 4 4 4" />
            </svg>
          ) : (
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m10 16 4-4-4-4"
              />
            </svg>
          )}
        </button>
      </div>
      {
        openedCard && (
          <div className="flex flex-col items-center p-10 flex-1 gap-2">
            <Markdown className="prose dark:prose-dark">{tutorProfileType.biography}</Markdown>
            <div className="flex justify-start gap-2 items-center w-full">
              <div className="flex gap-1 h-[70px] max-w-sm items-center my-2">
                <p className="-rotate-90 text-sm text-gray-500">Availability</p>
                <TimeRangeChart day="Mon" startTime={tutorProfileType.mondayAvailability.startTime} endTime={tutorProfileType.mondayAvailability.endTime} />
                <TimeRangeChart day="Tue" startTime={tutorProfileType.tuesdayAvailability.startTime} endTime={tutorProfileType.tuesdayAvailability.endTime} />
                <TimeRangeChart day="Wed" startTime={tutorProfileType.wednesdayAvailability.startTime} endTime={tutorProfileType.wednesdayAvailability.endTime} />
                <TimeRangeChart day="Thur" startTime={tutorProfileType.thursdayAvailability.startTime} endTime={tutorProfileType.thursdayAvailability.endTime} />
                <TimeRangeChart day="Fri" startTime={tutorProfileType.fridayAvailability.startTime} endTime={tutorProfileType.fridayAvailability.endTime} />
                <TimeRangeChart day="Sat" startTime={tutorProfileType.saturdayAvailability.startTime} endTime={tutorProfileType.saturdayAvailability.endTime} />
                <TimeRangeChart day="Sun" startTime={tutorProfileType.sundayAvailability.startTime} endTime={tutorProfileType.sundayAvailability.endTime} />
              </div>
              <button
                type="button"
                className="py-2 px-4 ms-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                View CV
              </button>
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Pick Tutor
              </button>
            </div>
          </div>
        )
      }
    </div>
  );
};
