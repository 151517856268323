import React, { useEffect, useState } from 'react';
import {
  emptyTutorDocument, TutorDocument, TutorOnboardingState,
} from '../../../../../types/collections/Tutor';
import { UserDocument } from '../../../../../types/collections/User';
import FirebaseFirestoreService from '../../../../../services/database/strategies/FirebaseFirestoreService';
import { BreadcrumbStepper } from '../../../../stepper/BreadcrumbStepper';
import { TutorInformation } from './TutorInformation';

export interface TutorOnboardingProps {
  userDoc: UserDocument;
}

const onboardingStateToStep: { [key in TutorOnboardingState]: number } = {
  'Account Created': 0,
  'Employment Information Complete': 1,
  'Account Reviewed': 2,
  'DBS Check Confirmed': 3,
  'Document Completed': 4,
};

export const TutorOnboarding: React.FC<TutorOnboardingProps> = ({ userDoc }) => {
  // Tutoring Information
  const [tutorDocument, setTutorDocument] = useState<TutorDocument>(emptyTutorDocument);
  const [tutorDocumentId, setTutorDocumentId] = useState('');
  const [onboardingStep, setOnboardingStep] = useState<TutorOnboardingState>('Account Created');

  useEffect(() => {
    FirebaseFirestoreService.queryDocuments(
      'tutors',
      'uid',
      userDoc.uid,
      'uid',
      1,
      undefined,
      (doc) => {
        if (doc.length > 0) {
          if (doc[0].id) setTutorDocumentId(doc[0].id);
          FirebaseFirestoreService.listenToDocument(
            'tutors',
            doc[0].id!,
            (tutorDoc) => {
              console.log(tutorDoc);
              setTutorDocument(tutorDoc as TutorDocument);
            },
            (error) => {
              console.error(error);
              setOnboardingStep('Account Created');
            },
          );
          setTutorDocument(doc[0] as TutorDocument);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }, [userDoc.uid]);

  useEffect(() => {
    setTutorDocument((prevState) => ({
      ...prevState,
      uid: userDoc.uid,
    }));
  }, [userDoc]);

  // const createOrUpdateTutorDocument = (values: Partial<TutorDocument>) => {
  //   if (authState.isAuthenticated && authState.user?.uid) {
  //     FirebaseFirestoreService.queryDocuments(
  //       'tutors',
  //       'uid',
  //       userDoc.uid,
  //       'uid',
  //       1,
  //       undefined,
  //       (doc) => {
  //         if (doc.length > 0) {
  //           console.log(doc[0].id);
  //           FirebaseFirestoreService.updateDocument(
  //             'tutors',
  //             doc[0].id!,
  //             values,
  //             () => { console.log('Successfully updated the userDocument'); },
  //             () => { console.log('Failed to update the userDocument'); },
  //           );
  //         } else {
  //           FirebaseFirestoreService.addDocument(
  //             'tutors',
  //             {
  //               ...tutorDocument,
  //               ...values,
  //             },
  //             (documentId) => {
  //               FirebaseFirestoreService.listenToDocument(
  //                 'tutors',
  //                 documentId,
  //                 (tutorDoc) => {
  //                   console.log(tutorDoc);
  //                   setTutorDocument(tutorDoc as TutorDocument);
  //                 },
  //                 (error) => {
  //                   console.error(error);
  //                 },
  //               );
  //               console.log('Created a new Tutor Document');
  //             },
  //             () => {
  //               console.log('Failed to add a new Tutor Document');
  //             },
  //           );
  //         }
  //       },
  //       (error) => {
  //         console.log(error);
  //       },
  //
  //     );
  //   } else {
  //     console.error('User Not Authenticated');
  //   }
  // };

  return (
    <div className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <div className="p-4 bg-white rounded-lg dark:bg-gray-800" id="faq" role="tabpanel" aria-labelledby="faq-tab">
        <div id="accordion-flush" data-accordion="collapse" data-active-classes="bg-white dark:bg-gray-800 text-gray-900 dark:text-white" data-inactive-classes="text-gray-500 dark:text-gray-400">
          {/* Tutoring Onboarding Progress */}
          <div className="flex flex-col sm:flex-row flex-wrap w-full p-5 py-8 gap-5 sm:gap-10 items-center justify-center">
            <BreadcrumbStepper
              stepCompletedIndex={onboardingStateToStep[onboardingStep]}
              stepNames={[
                'Review Tutor Information',
                'Kick-Off DBS Process',
                'Await DBS Confirmation',
                'Report',
              ]}
            />
          </div>

          <TutorInformation
            tutorDocument={tutorDocument}
            tutorDocId={tutorDocumentId}
          />
        </div>
      </div>
    </div>
  );
};
