import React, { useEffect, useState } from 'react';
import { ExampleTutorProfileCard, TutorProfileType } from '../../types/TutorProfile';
import FirebaseFirestoreService from '../../services/database/strategies/FirebaseFirestoreService';
import { UserDocument } from '../../types/collections/User';
import { TutorProfileCard } from '../tutor-profile-card/TutorProfileCard';

export interface TutorProfileSectionProps {
  uid: string;
  userDoc: UserDocument;
}

export const TutorProfileSection: React.FC<TutorProfileSectionProps> = ({ uid, userDoc }) => {
  const [tutorProfileType, setTutorProfileType] = useState<TutorProfileType>(
    ExampleTutorProfileCard,
  );

  useEffect(() => {
    FirebaseFirestoreService.queryDocuments(
      'tutor-profile',
      'uid',
      uid,
      'confirmed',
      1,
      undefined,
      (docs) => {
        if (docs.length > 0) {
          setTutorProfileType(docs[0] as TutorProfileType);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }, [uid]);

  return (
    <div className="flex w-full gap-3 items-center flex-col">
      <TutorProfileCard userDoc={userDoc} tutorProfileType={tutorProfileType} />
      <div className="flex items-center justify-center gap-4">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
        >
          Message User
        </button>
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-greeb-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          onClick={() => {
            const confirmedTutorProfileType: TutorProfileType = {
              ...tutorProfileType,
              confirmed: true,
            };
            FirebaseFirestoreService.updateDocument(
              'tutor-profile',
              uid,
              confirmedTutorProfileType,
              () => {
                console.log('Updated the tutor profile');
              },
              () => {
                console.error('Failed to update the tutor profile');
              },
            );
          }}
        >
          Confirm Topic
        </button>
      </div>
    </div>
  );
};
