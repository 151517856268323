import React, { useState } from 'react';
import { TutorDocument } from '../../../../../../types/collections/Tutor';

interface ReferencesUploadProps {
  referencesUploaded: boolean;
  tutorDoc: TutorDocument;
  tutorDocumentId: string;
}

interface ReferenceData {
  number: number;
  emailKey: 'reference1Email' | 'reference2Email';
  sentKey: 'reference1EmailSent' | 'reference2EmailSent';
}

const ReferencesUpload: React.FC<ReferencesUploadProps> = (
  { referencesUploaded, tutorDoc, tutorDocumentId },
) => {
  const [isLoading, setIsLoading] = useState<{ [key: number]: boolean }>({ 1: false, 2: false });
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const referenceData: ReferenceData[] = [
    { number: 1, emailKey: 'reference1Email', sentKey: 'reference1EmailSent' },
    { number: 2, emailKey: 'reference2Email', sentKey: 'reference2EmailSent' },
  ];

  const sendReferenceEmail = async (referenceNumber: number) => {
    setIsLoading((prev) => ({ ...prev, [referenceNumber]: true }));
    setError(null);
    setSuccess(null);

    try {
      const response = await fetch('https://send-reference-email-ltt4b4eg6q-ew.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tutorId: tutorDocumentId,
          referenceNumber,
        }),
      });
      console.log(response);
      const data = await response.json();
      console.log('data', data);
      if (data.success) {
        setSuccess(data.message);
      } else {
        throw new Error(data.message);
      }
    } catch (errorN) {
      console.log(errorN);
      setError(`Error sending reference ${referenceNumber} email: ${errorN instanceof Error ? errorN.message : String(errorN)}`);
    } finally {
      setIsLoading((prev) => ({ ...prev, [referenceNumber]: false }));
    }
  };

  return (
    <div hidden={referencesUploaded} id="accordion-flush-body-3" aria-labelledby="accordion-flush-heading-3">
      <div className="py-5 border-b border-gray-200 dark:border-gray-700">
        <p className="mb-2 text-gray-500 dark:text-gray-400">We also need two references from people you have previously worked with. Try to keep these references as relevant as possible.</p>

        {error && <p className="text-red-500 mb-2">{error}</p>}
        {success && <p className="text-green-500 mb-2">{success}</p>}

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">Reference Email</th>
                <th scope="col" className="px-6 py-3">Reference Number</th>
                <th scope="col" className="px-6 py-3">Email Sent Date</th>
                <th scope="col" className="px-6 py-3">Send Email Request</th>
              </tr>
            </thead>
            <tbody>
              {referenceData.map((ref) => (
                <tr key={ref.number} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {tutorDoc[ref.emailKey]}
                  </th>
                  <td className="px-6 py-4">
                    Reference
                    {' '}
                    {ref.number}
                  </td>
                  <td className="px-6 py-4">
                    {tutorDoc[ref.sentKey]?.toDate().toDateString()}
                  </td>
                  <td className="px-6 py-4">
                    <button
                      type="button"
                      className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 my-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 disabled:opacity-50"
                      onClick={() => sendReferenceEmail(ref.number)}
                      disabled={!!tutorDoc[ref.sentKey] || isLoading[ref.number]}
                    >
                      {isLoading[ref.number] ? 'Sending...' : 'Email Reference'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ReferencesUpload;
