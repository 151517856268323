import React, { useCallback, useEffect, useState } from 'react';
import Identicon from 'react-hooks-identicons';
import { UserDocument } from '../../../types/collections/User';
import FirebaseFirestoreService from '../../../services/database/strategies/FirebaseFirestoreService';

export interface StudentsProps {
}

export interface StudentCardProps {
  userDoc: UserDocument,
  userIdSelected: string,
  setUserId: React.Dispatch<React.SetStateAction<string>>
}

const StudentCard: React.FC<StudentCardProps> = ({ userDoc, userIdSelected, setUserId }) => (
  <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <div className="flex justify-end px-4 pt-4">
      <button id="dropdownButton" onClick={() => { setUserId(userDoc.uid); }} data-dropdown-toggle="dropdown" className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5" type="button">
        <span className="sr-only">Open dropdown</span>
        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
          <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
        </svg>
      </button>
      <div hidden={userDoc.uid !== userIdSelected} id="dropdown" className="absolute z-10 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
        <ul className="py-2" aria-labelledby="dropdownButton">
          <li>
            <button type="button" onClick={() => { setUserId(''); }} className="w-full block px-4 py-2 text-sm text-blue-600 hover:bg-blue-100 dark:hover:bg-blue-600 dark:text-gray-200 dark:hover:text-white">View Details</button>
          </li>
          <li>
            <button type="button" onClick={() => { setUserId(''); }} className="w-full block px-4 py-2 text-sm text-red-600 hover:bg-red-100 dark:hover:bg-red-600 dark:text-red-200 dark:hover:text-white">Close</button>
          </li>
        </ul>
      </div>
    </div>
    <div className="flex flex-col items-center pb-10 gap-2">
      <Identicon string={userDoc.email} size={100} />
      <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">{userDoc.fullName}</h5>
      <span className="text-sm text-gray-500 dark:text-gray-400">{userDoc.email}</span>
      <span className="text-sm text-gray-500 dark:text-gray-400">{userDoc.phoneNumber}</span>
      <div className="flex mt-4 md:mt-6">
        <a href={`/users?uid=${userDoc.uid}`} className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">View Profile</a>
        <a href={`mailto:${userDoc.email}?subject=Tutr.ai%20Email&body=Hi%20${userDoc.fullName},%0D%0A%0D%0AHow%20are%20you%3F`} className="py-2 px-4 ms-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Contact</a>
      </div>
    </div>
  </div>
);

export const Students: React.FC<StudentsProps> = () => {
  const [userIdSelected, setUserId] = useState('');
  const [users, setUsers] = useState<UserDocument[]>([]);
  const pageSize = 20;

  const getNewPage = useCallback((lastUserDocId?: string) => {
    FirebaseFirestoreService.queryDocuments(
      'users',
      'accountType',
      'student',
      'fullName',
      pageSize,
      lastUserDocId,
      (docs) => {
        setUsers(docs.map((elem) => elem as UserDocument));
      },
      (error) => {
        console.log('Failed to get documents', error);
      },
    );
  }, [setUsers]);

  useEffect(() => {
    getNewPage(undefined);
  }, [getNewPage]);

  return (
    <div className="w-full h-full">
      <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-2">
        Manage Students
      </h3>
      <p className="mb-2">View student profiles, track progress, and manage enrollments.</p>

      <div className="flex-1 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 ">
        {
          users.map((user) => (
            <StudentCard
              key={user.uid}
              userDoc={user}
              setUserId={setUserId}
              userIdSelected={userIdSelected}
            />
          ))
        }

        {users.length === 0 && (
          <div>
            <p className="text-blue-400 font-bold animate-bounce">Loading...</p>
          </div>
        )}
      </div>

      {users.length > 0 && (
        <nav aria-label="Page navigation example" className="w-full flex items-center justify-center mt-5">
          <ul className="inline-flex -space-x-px text-sm ">
            <li>
              <a href="/" className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Previous</a>
            </li>
            <li>
              <a href="/" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">1</a>
            </li>
            <li>
              <a href="/" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">2</a>
            </li>
            <li>
              <a href="/" aria-current="page" className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">3</a>
            </li>
            <li>
              <a href="/" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">4</a>
            </li>
            <li>
              <a href="/" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">5</a>
            </li>
            <li>
              <a href="/" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default Students;
