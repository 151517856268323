import firestore from 'firebase/firestore';

export interface TutorDocument {
  uid: string,
  cvUploadedLocation: string,
  cvUploadedDate?: Date,
  cvApproved?: boolean,
  identificationUploadedLocation: string,
  identificationUploadedDate?: Date,
  identificationApproved?: boolean,
  reference1Email: string,
  reference1ApproveDate?: Date,
  reference1EmailSent?: firestore.Timestamp,
  reference2Email: string,
  reference2ApproveDate?: Date,
  reference2EmailSent?: firestore.Timestamp,
  uniformAgreementDate?: Date,
  employmentStatusAgreementDate?: Date,
  tutorApplicationConfirmed?: Date,
  dbsCheckSend?:Date,
  dbsCheckConfirmed?: Date,
}

export const emptyTutorDocument: TutorDocument = {
  uid: '',
  cvUploadedLocation: '',
  identificationUploadedLocation: '',
  reference1Email: '',
  reference2Email: '',
};

export type TutorOnboardingState = 'Account Created' | 'Employment Information Complete' | 'Account Reviewed' |'DBS Check Confirmed' | 'Document Completed';

export const getTutorOnboardingState = (tutorDocument: TutorDocument): TutorOnboardingState => {
  if (tutorDocument.dbsCheckConfirmed !== undefined) return 'DBS Check Confirmed';
  if (tutorDocument.tutorApplicationConfirmed !== undefined) return 'Account Reviewed';
  if (
    tutorDocument.cvUploadedLocation !== ''
    && tutorDocument.identificationUploadedLocation !== ''
    && tutorDocument.reference1Email !== '' && tutorDocument.reference2Email !== ''
    && tutorDocument.uniformAgreementDate !== undefined
    && tutorDocument.employmentStatusAgreementDate !== undefined
  ) {
    return 'Employment Information Complete';
  }
  return 'Account Created';
};
